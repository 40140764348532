import Vue from 'vue'
import App from './App.vue'
import api from "@/utils/api.js";
import Toast from "@/components/toast";
import { router, VueRouter } from "@/router"
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.prototype.toast = function (msg, delay) {
  let controller = Vue.extend(Toast);
  let components = new controller();
  let Tpl = components.$mount().$el;
  components.message = msg
  components.delay = delay ? delay : 3000
  document.body.appendChild(Tpl)
};
Vue.prototype.api = api;
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
