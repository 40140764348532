import axios from 'axios';
import Storage from "@/utils/storage.js";
var instance = axios.create({ timeout: 1000 * 100 });
let baseURL = "https://api.xm.xi-life.cn"
//let baseURL = process.env.NODE_ENV == "development" ? "http://localhost" : "https://api.xm.xi-life.cn"
instance.interceptors.request.use(
  config => {
    config.baseURL = baseURL
    let token = Storage.get('token')
      ? Storage.get('token')
      : '';
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    return Promise.reject(error);
  }
);
export default {
  //get请求，其他类型请求复制粘贴，修改method
  axios,
  get(url, param, other) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'get',
        url,
        params: param,
        ...other,
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  //post
  post(url, param, other) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'post',
        url,
        data: param,
        ...other,
      })
        .then(res => {
          if (res.status == 200) {
            resolve(res);
          } else {
            console.log(res, '异常1');
          }
        })
        .catch(err => {
          if (!err.response) {
            reject('cancel');
            console.log('请求错误');
          } else {
            reject(err.response);
            console.log(err.response.data[0], '异常2');
          }
        });
    });
  },
  //post
  delete(url, param) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'delete',
        url,
        data: param,
      })
        .then(res => {
          //axios返回的是一个promise对象
          if (res.status == 200) {
            resolve(res); //resolve在promise执行器内部
          } else {
            console.log(res, '异常1');
          }
        })
        .catch(err => {
          if (!err.response) {
            console.log('请求错误');
          } else {
            reject(err.response);
            console.log(err.response.data[0], '异常2');
          }
        });
    });
  },
  //put
  put(url, param) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'put',
        url,
        data: param,
      })
        .then(res => {
          //axios返回的是一个promise对象
          if (res.status == 200) {
            resolve(res); //resolve在promise执行器内部
          } else {
            console.log(res, '异常1');
          }
        })
        .catch(err => {
          if (!err.response) {
            console.log('请求错误');
          } else {
            reject(err.response);
            console.log(err.response.data[0], '异常2');
          }
        });
    });
  },
  downLoad(url, param) {

    return new Promise((resolve, reject) => {
      instance({
        method: 'get',
        url,
        params: param,
        responseType: 'blob',
      })
        .then(res => {

          // console.log('ce',res.status)
          //axios返回的是一个promise对象
          if (res.status == 200) {
            resolve(res); //resolve在promise执行器内部
          } else {
            console.log(res, '异常1');
          }
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};
