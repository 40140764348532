<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  methods: {}
};
</script>

<style lang="less">
@import url("@/assets/css/common.less");
@import url("//at.alicdn.com/t/font_2072256_j26pdeue5d.css");
</style>
