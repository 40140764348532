<template>
  <div class="loading">
    <div class="three1"></div>
    <div class="three2"></div>
    <div class="three3"></div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>