<template>
  <div class="salary">
    <div class="salary_header">
      <div class="date_card">
        <div class="date_card_item">
          <div class="card_item_content">
            <span class="iconfont iconleft" @click="changeDate('reduce')"></span>
            <p>实发工资</p>
            <h3>{{salary.totalPaid | retain2Dots}}</h3>
            <div>工资表名称:工资条-{{year}}年{{month}}月</div>
            <span class="iconfont iconright" @click="changeDate('add')"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="salary_detail" v-if="status==3">
      <div class="salary_detail_content">
        <div class="salary_detail_item">
          <div class="salary_label">工资:</div>
          <div class="salary_res">{{salary.salary | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">奖金:</div>
          <div class="salary_res">{{salary.bouns | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">税前合计:</div>
          <div class="salary_res">{{salary.totalBeforeTax | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">社保:</div>
          <div class="salary_res">{{salary.social}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">公积金:</div>
          <div class="salary_res">{{salary.accumulationFund | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">子女教育抵扣:</div>
          <div class="salary_res">{{salary.childrenEducationDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">继续教育抵扣:</div>
          <div class="salary_res">{{salary.educationDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">房贷抵扣:</div>
          <div class="salary_res">{{salary.houseLoanDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">住房租金抵扣:</div>
          <div class="salary_res">{{salary.rentDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">赡养老人抵扣:</div>
          <div class="salary_res">{{salary.suuportDeduction | retain2Dots}}</div>
        </div>
          <div class="salary_detail_item">
          <div class="salary_label">婴幼儿照护费:</div>
          <div class="salary_res">{{salary.childDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">个税:</div>
          <div class="salary_res">{{salary.tax | retain2Dots}}</div>
        </div>
         <div class="salary_detail_item">
          <div class="salary_label">借款:</div>
          <div class="salary_res">{{salary.loan | retain2Dots}}</div>
        </div>
        
        <div class="salary_detail_item">
          <div class="salary_label">罚款:</div>
          <div class="salary_res">{{salary.fine | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">应扣合计:</div>
          <div class="salary_res">{{salary.totalDeduction | retain2Dots}}</div>
        </div>
        <div class="salary_detail_item">
          <div class="salary_label">实发合计:</div>
          <div class="salary_res">{{salary.totalPaid | retain2Dots}}</div>
        </div>
        <div style="width: 100%; height: 10px;"></div>
        <div class="salary_detail_item">
          <div class="btn" v-if="salary.confirmState == 0" @click="salaryConfirm" style="background-color: #409EFF; color: white; width: 100%; text-align: center; border-radius: 6px">
            <span>确 认</span>
          </div>
          <div class="btn" v-else style="background-color:lightgray; color: black; width: 100%; text-align: center; border-radius: 6px">
            <span>已确认</span>
          </div>
        </div>
        <div style="width: 100%; height: 10px;"></div>
      </div>
    </div>
    <div class="loading_wrap" v-else-if="status==1">
      <Loading></Loading>
    </div>
    <div class="tips_wrap" v-else>当前日期暂无工资条信息</div>
  </div>
</template>

<script>
import getQueryVariable from "@/utils/getQueryVariable.js";
import Storage from "@/utils/storage.js";
import Loading from "@/components/loading";
import { Scope_url } from "@/env.js";
export default {
  name: "Index",
  data() {
    return {
      salary: {
        salary: "",
        bouns: "",
        totalBeforeTax: "",
        social: "",
        accumulationFund: "",
        childrenEducationDeduction: "",
        educationDeduction: "",
        houseLoanDeduction: "",
        rentDeduction: "",
        suuportDeduction: "",
        childDeduction: "",
        tax: "",
        loan: "",
        fine: "",
        totalDeduction: "",
        totalPaid: "",
        confirmState: 1 //0=>未确认 1=>手动确认 2=>系统确认
      },
      year: new Date().getFullYear(),
      month: new Date().getMonth() == 0 ? 12 : new Date().getMonth(),
      status: 1 //1 => loading 2=> 无工资信息 3=>有工资信息
    };
  },
  components: {
    Loading
  },
  mounted() {
    if (!getQueryVariable("code")) {
      window.location.href = Scope_url;
    } else {
      let date = getQueryVariable("date");
      if (date) {
        this.year = Number(date.split("-")[0]);
        this.month = Number(date.split("-")[1]);
      }
      this.getOpenid(getQueryVariable("code"));
    }
  },
  methods: {
    getOpenid: function(code) {
      this.api
        .get("xsss/v1/staff/authorization", {
          code: code
        })
        .then(res => {
          Storage.set("token", res.data.token);
          this.getSalary();
        })
        .catch(err => {
          if (err.response && err.response.status == 403) {
            window.location.href = Scope_url;
          }
        });
    },
    getSalary: function() {
      this.status = 1;
      this.api
        .get("xsss/v1/staff/salary", {
          year: this.year,
          month: this.month
        })
        .then(res => {
          this.status = 3;
          this.salary = res.data;
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$router.push("/login");
          } else if (err.response && err.response.status == 404) {
            this.status = 2;
          } else {
            this.status = 2;
            this.toast("服务器异常");
          }
        });
    },
    changeDate: function(type) {
      type == "add"
        ? this.month++
        : type == "reduce"
        ? this.month--
        : console.warn('changeDate func参数为"add"/"reduce"');
      this.month > 12
        ? (this.year++, (this.month = 1))
        : this.month < 1
        ? (this.year--, (this.month = 12))
        : null;
      this.getSalary();
    },
    salaryConfirm: function() {
      if (this.salary.confirmState != 0) {
        return;
      }
      this.api.post("xsss/v1/staff/salaryConfirm", {
          year: this.year,
          month: this.month
        })
        .then(() => {
          this.salary.confirmState = 1;
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$router.push("/login");
          } else if (err.response && err.response.status == 404) {
            this.status = 2;
          } else {
            this.status = 2;
            this.toast("服务器异常");
          }
        });
    }
  },
  filters: {
    retain2Dots: function(val) {
      try {
        return Number(val).toFixed(2);
      } catch (err) {
        console.log(err);
        return 0.0;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import url("./index.less");
</style>
