<template>
  <div class="login">
    <div class="login_header">
      <img class="logo" src="../../assets/image/xique.png" alt />
      <span>设计你想要的生活</span>
    </div>
    <div class="login_input">
      <div
        class="common_input phone_input"
        :class="{error:(submited&&!phone.length)||(smsGeted&&!phone.length)}"
      >
        <span class="label">电话号码:</span>
        <input type="text" placeholder="请输入电话号码" v-model="phone" />
      </div>
      <div class="common_input code_input" :class="{error:submited&&!code.length}">
        <span class="label">验证码:</span>
        <input type="text" placeholder="请输入验证码" v-model="code" />
        <div class="sms_code" @click="getSmsCode">{{smsStart?countdown+"s":"验证码"}}</div>
      </div>
      <div class="btn" @click="login">
        <span>登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import getQueryVariable from "@/utils/getQueryVariable.js";
import Storage from "@/utils/storage.js";
import { Scope_url } from "@/env.js";
let timer;
export default {
  name: "Login",
  data() {
    return {
      phone: "",
      code: "",
      submited: false,
      smsGeted: false,
      smsStart: false,
      countdown: 60
    };
  },
  mounted() {
    this.authorization(getQueryVariable("code"));
  },
  methods: {
    authorization: function() {
      let code = getQueryVariable("code");
      if (!Storage.get("token")) {
        if (code) {
          this.api
            .get("xsss/v1/staff/authorization", {
              code: code
            })
            .then(res => {
              Storage.set("token", res.data.token);
            })
            .catch(err => {
              if (err.response && err.response.status == 403) {
                window.location.href = Scope_url;
              }
            });
        } else {
          window.location.href = Scope_url;
        }
      }
    },
    login: function() {
      this.submited = true;
      if (this.phone.length != 11) {
        this.toast("请输入正确手机号码");
        return;
      }
      if (!this.code.length) {
        return;
      }
      this.api
        .post("xsss/v1/login", {
          phone: this.phone,
          code: this.code
        })
        .then(() => {
          this.$router.push("/");
        });
    },
    getSmsCode: function() {
      this.smsGeted = true;
      if (this.phone.length != 11) {
        this.toast("请输入正确手机号码");
        return;
      }
      if (this.smsStart) {
        return;
      }
      this.api
        .get("xsss/v1/smscode", {
          phone: this.phone
        })
        .then(() => {
          this.smsStart = true;
          timer = setInterval(() => {
            if (this.countdown < 1) {
              clearInterval(timer);
              this.countdown = 60;
              this.smsStart = false;
            }
            this.countdown--;
          }, 1000);
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>