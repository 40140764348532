<template>
  <div class="toast">{{message}}</div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      message: "",
      delay: 3000,
      timer: null
    };
  },
  mounted: function() {
    this.timer = setTimeout(() => {
      this.$el.remove();
      clearTimeout(this.timer);
    }, this.delay);
  }
};
</script>

<style lang="less" scoped>
.toast {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  padding: 5px 16px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  animation: show 0.6s;
}
@keyframes show {
  0% {
    top: -100px;
  }
  100% {
    top: 60px;
  }
}
</style>