import VueRouter from "vue-router"
import Index from "@/container/index"
import Login from "@/container/login"
const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
]
const router = new VueRouter({
  mode: "history",
  routes
})
export { router, VueRouter }